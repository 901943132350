import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";


const Articles = () => {
	return (
		<div>
			<SEO
				title="Newsletters/Blog Posts"
				keywords={[
					`Blog`,
					`Newsletters`,
					`Information`,
					`Research`,
					`Endoscopy`,
					`Gastroenterologist`,
					`Dr Marcelo Leal`,
					`A/Prof Spiro Raftopoulos`,
					`Prof Ian Yusoff`,
					`Dr Katrina Rogers`,
					`Dr Sam Rao`,
					`Dr Claire Gordon`,
					`Dr Duy Pham`,
					`Dr Danielle Lam`,
					`Dr Angel Li`,
					`Dr Nabil Siddique`,
					`Dr Daniel Venetz`,
					`Dr Daniel Cheng`,
					`A/Prof Matt Zimmerman`,
					`Dr Muna Salama`,
					`Dr Hannah Hessamodini`,
				]}
				description="Gastroenterology and Endoscopy related articles and blog posts"
			/>
			<H1 text="Doctors Monthly Newsletters" />
			<ul>
				<a href="https://us4.campaign-archive.com/?u=bdf8e2061ac96e8dc901ccf96&id=3af5aeeea5" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					May 2022 - Acute Pancreatitis - A 5-minute update {" "}
				</a>
			</ul>
			<ul>
				<a href="https://us4.campaign-archive.com/?u=bdf8e2061ac96e8dc901ccf96&id=b5acd5abe9" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					April 2022 - COVID-19 and the Gastrointestinal Tract {" "}
				</a>
			</ul>
			<ul>
				<a href="https://us4.campaign-archive.com/?u=bdf8e2061ac96e8dc901ccf96&id=7e0b01361e" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					March 2022 - Safe management of pain in decompensated cirrhosis {" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hVOh8v" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					February 2022 - Eosinophilic Oesophagitis - a 5 minute update{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hN3cR1" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					November 2021 - Diet in Inflammatory Bowel Disease – What do we know?{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hLC889" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					October 2021 - Update in Bowel Cancer{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hIMJJv" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					September 2021 - Overlooked causes in the investigation and management of chronic diarrhoea{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hFNSB1" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					August 2021 - Increasing incidence of bowel cancer in the under 50s.Why is this occurring and what can be done to prevent it?{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hDALvj" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					July 2021 - Autoimmune Hepatitis{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hBMtST" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					June 2021 - Helicobacter Pylori – Everything you need to know in 5 minutes{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hydkPn" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					May 2021 - COVID-19 vaccination in Inflammatory Bowel Disease patients{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/htUX2n" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					March 2021 - Gastro-oesophageal reflux disease (GORD){" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hp16JH" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					Feb 2021 - Irritable Bowel Syndrome{" "}
				</a>
			</ul>
			<ul>
				<a href="http://eepurl.com/hnr6jj" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					Jan 2021 - Small Bowel Bleeding Update{" "}
				</a>
			</ul>
			<ul>
				<a href="https://mailchi.mp/36ee0baeed82/advanced-gi-wa-newsletter-9589916" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					November 2020 - 10 Questions about non-alcoholic fatty liver disease (NAFLD){" "}
				</a>
			</ul>
			<ul>
				<a href="https://mailchi.mp/ed5f3c335dd4/advanced-gi-wa-newsletter-9558284" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					October 2020 - Preventative Healthcare in Inflammatory Bowel Disease{" "}
				</a>
			</ul>
			<ul>
				<a href="https://mailchi.mp/ddafbd3e0375/advanced-gi-wa-newsletter" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					September 2020 - I found a pancreatic cyst, now what?{" "}
				</a>
			</ul>
			<ul>
				<a href="https://podcast.mforum.com.au/episode/saturn-pathology-podcast-childhood-abdominal-pain-with-dr-marcelo-leal" className="no-underline text-primary hover:primary-dark hover:font-bold" target="_blank" rel="noopener noreferrer">
					February 2024 - Childhood Abdominal Pain (Podcast with Dr Marcelo Leal){" "}
				</a>
			</ul>
		</div>
	);
};

export default Articles;
